/**
 * VARIABLES
 */
/**
 * BREAKPOINTS
 */
/**
 * COLOR PALETTE
 * First, we define out color palette, naming the variables based on the
 * visual appearance of the color, rather than it's usage.
 */
/**
 * COLOR MAPPINGS
 * Next, we map our color palette onto specific, common usages of that color.
 * This gives us the ability to change the color palette and usages of the
 * palette independently. For example, we could change all the text to black,
 * without changing everything that is dark brown to also be black.
 */
/**
 * PADDING
 * We define a basic set of padding levels, and all components should base
 * their padding values as multiples of these base levels. That way we can
 * adjust the overall 'compactness' of the website by adjusting these values.
 */
/**
 * THIRD-PARTY LIBRARIES
 */
/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger {
  padding: 0 0;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible; }
  .hamburger:hover {
    opacity: 0.7; }

.hamburger-box {
  width: 24px;
  height: 19px;
  display: inline-block;
  position: relative; }

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -1.5px; }
  .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
    width: 24px;
    height: 3px;
    background-color: #ffffff;
    border-radius: 4px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease; }
  .hamburger-inner::before, .hamburger-inner::after {
    content: "";
    display: block; }
  .hamburger-inner::before {
    top: -8px; }
  .hamburger-inner::after {
    bottom: -8px; }

/*
   * Spin
   */
.hamburger--spin .hamburger-inner {
  transition-duration: 0.22s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--spin .hamburger-inner::before {
    transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in; }
  .hamburger--spin .hamburger-inner::after {
    transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spin.is-active .hamburger-inner {
  transform: rotate(225deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--spin.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0;
    transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out; }
  .hamburger--spin.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(-90deg);
    transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1); }

/**
 * GENERIC
 */
/**
 * BREAKPOINT
 * A standard breakpoint, targeting everything above the breakpoint, using
 * a `min-width` media query.
 */
html,
body {
  font-size: 14px;
  margin: 0;
  color: #222222; }

* {
  box-sizing: border-box; }

a {
  color: #be3690;
  word-break: break-all;
  word-break: break-word; }

img {
  max-width: 100%; }

@media (hover: none) {
  a,
  button {
    outline: none; } }

/**
 * ELEMENTS
 */
ul,
li {
  list-style: none;
  margin: 0;
  padding: 0; }

table {
  border-spacing: 0;
  width: 100%; }

thead {
  font-weight: bold; }

th,
td {
  border-bottom: 2px solid #eeeeee;
  padding: 7px 14px;
  text-align: left;
  vertical-align: top; }

th:first-child,
td:first-child {
  padding-left: 0; }

th:last-child,
td:last-child {
  padding-right: 0; }

body,
input,
textarea,
select,
button {
  color: #222222;
  font-family: "Raleway", sans-serif;
  font-size: 14px;
  line-height: 21px; }

h1, h2, h3 {
  font-weight: bold;
  margin: 0.5em 0; }

h1 {
  font-size: 2rem;
  margin: 0.5em 0;
  padding: 0.5em 0.5em;
  padding-right: 3em;
  position: relative;
  overflow: hidden;
  color: white; }
  h1::before {
    content: '';
    z-index: -1;
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-image: linear-gradient(45deg, #be3690, #ed324b);
    transform-origin: 100% 0%; }
  h1::after {
    content: '';
    z-index: -1;
    display: block;
    position: absolute;
    width: 200px;
    height: 200px;
    top: -4em;
    right: -4.5em;
    background: repeating-linear-gradient(105deg, #ffffff, #ffffff 3px, transparent 3px, transparent 10px);
    transform: rotate(30deg); }

h2 {
  font-size: 1.25rem;
  margin-top: 0.5rem; }

h3 {
  font-size: 1rem; }

h1 + h2 {
  margin-top: 0; }

h1, h2, h3, h4 {
  line-height: 1.25; }

/**
 * OBJECTS (o-)
 */
.o-team {
  display: flex; }

.o-team__logo {
  background-color: #eeeeee;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  flex-shrink: 0;
  height: 39.66667px;
  width: 39.66667px; }

.o-team__logo--large {
  width: 200px; }

.o-team__name {
  padding: 4.66667px 0; }

/**
 * COMPONENTS (c-)
 */
.c-address {
  margin: 40px;
  font-size: 20pt;
  font-weight: bold;
  line-height: 1.4; }

.c-button {
  cursor: pointer;
  display: block;
  padding: 7px;
  width: 100%;
  font-weight: bold;
  text-decoration: none; }

.c-button--default {
  background: #ffffff;
  color: #222222;
  border: 2px solid #222222; }
  .c-button--default.is-active {
    background: #222222;
    border: 2px solid #222222;
    color: #ffffff; }

.c-button--primary {
  background: #222222;
  border: 2px solid #222222;
  color: #ffffff;
  padding: 7px 14px; }
  .c-header .c-button--primary {
    color: #ed324b;
    background: #ffffff;
    border: 2px solid #ffffff; }

.c-button--subtle {
  background: transparent;
  border: 2px solid transparent;
  color: inherit; }

@media (min-width: 480px) {
  .c-button {
    display: inline-block;
    width: auto; }
  .c-button--block {
    display: block;
    width: 100%; } }

.c-button-group {
  margin-bottom: 14px; }

.c-button-group__button {
  margin-bottom: 7px; }

@media (min-width: 480px) {
  .c-button-group__button {
    margin-bottom: 0;
    margin-right: 7px; } }

.c-fixture {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 4.66667px 0;
  border-top: 2px solid #eeeeee;
  align-items: flex-start; }

.c-fixture__home,
.c-fixture__away {
  flex-grow: 1;
  flex-basis: 5rem; }

.c-fixture__home {
  padding-right: 4.66667px; }

.c-fixture__away {
  padding-left: 4.66667px;
  text-align: right;
  flex-direction: row-reverse; }

.c-fixture__scores,
.c-fixture__info {
  padding: 4.66667px;
  font-weight: bold;
  flex-basis: 4rem;
  text-align: center; }

.c-fixture__scores {
  background: #393e75;
  color: white;
  text-decoration: none; }

.c-fixture__info {
  background: #eeeeee; }

.c-fixture__extra {
  width: 100%;
  text-align: center; }

.c-fixture__number {
  width: 5%;
  text-align: center;
  flex-basis: 5rem; }

@media (min-width: 768px) {
  .c-fixture--full .c-fixture__home,
  .c-fixture--full .c-fixture__away {
    max-width: 20rem; }
  .c-fixture--full .c-fixture__extra {
    width: auto;
    flex-basis: 5rem;
    flex-grow: 1;
    text-align: left;
    padding: 4.66667px 0;
    padding-left: 2rem; } }

.c-footer {
  height: 10vw;
  min-height: 5rem;
  padding: 5vw 0;
  z-index: 10;
  text-align: center; }

.c-footer a {
  font-size: 3rem;
  margin: 5vw; }

.c-footer .ukactive {
  padding-top: 40px; }
  .c-footer .ukactive img {
    height: 80px; }

.c-gallery {
  display: flex;
  flex-wrap: wrap;
  margin: -0.5rem; }

.c-gallery__thumbnail {
  width: 50%;
  padding: 0.5rem; }
  .c-gallery__thumbnail > img {
    display: block;
    max-width: 100%; }
  @media (min-width: 768px) {
    .c-gallery__thumbnail {
      width: 33.333%; }
      .c-gallery__thumbnail:nth-child(n+4) {
        display: none; } }
  @media (min-width: 1024px) {
    .c-gallery__thumbnail {
      width: 25%; }
      .c-gallery__thumbnail:nth-child(n+4) {
        display: block; } }

.c-header {
  height: 10vw;
  min-height: 5rem;
  position: relative;
  transition: height 0.5s ease-in-out;
  z-index: 10; }

.c-header__square {
  width: 10vw;
  min-width: 5rem;
  max-width: 9rem;
  height: 10vw;
  min-height: 5rem;
  max-height: 9rem; }

.c-header__logo {
  top: 0;
  left: calc(100% - 16vw);
  position: absolute;
  transform-origin: left center;
  z-index: 11; }
  .c-header__logo img {
    width: 100%; }

.c-header__flyout-icon {
  position: absolute;
  top: 0;
  left: -14px; }

.c-header__region {
  position: relative;
  top: 1.5rem;
  opacity: 1;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
  color: white;
  text-align: center; }

.c-header__nav {
  position: relative;
  top: 4rem;
  overflow: hidden;
  height: 0;
  opacity: 0;
  transition: all 0.5s ease-in-out;
  color: white; }

.c-header__region-nav {
  position: relative;
  top: 2rem;
  overflow: hidden;
  height: 0;
  opacity: 0;
  transition: all 0.5s ease-in-out;
  background-color: white; }

.c-header__slash {
  position: absolute;
  left: 50%;
  width: 100vw;
  margin-left: -50vw;
  height: 10vw;
  min-height: 5rem;
  transition: height 0.5s ease-in-out; }

.c-header__slash-1 {
  background: linear-gradient(45deg, #ed324b 8rem, #be3690); }

.c-header__slash-2 {
  background: #ffffff; }

.c-header__slash-3 {
  background: linear-gradient(15deg, #4756a3, #2e2e49); }

.c-header.is-open,
.c-header.is-open .c-header__slash-2,
.c-header.is-open .c-header__slash-3 {
  height: 65rem; }

.c-header.is-open .c-header__nav {
  height: 37rem;
  opacity: 1; }

.c-header.is-open .c-header__region-nav {
  height: calc(19rem);
  opacity: 1; }

.c-header__top {
  position: absolute;
  width: 100vw;
  left: 50%;
  margin-left: -50vw;
  height: 1rem;
  overflow: hidden;
  background: repeating-linear-gradient(-45deg, transparent, transparent 7px, #ffffff 7px, #ffffff 10px); }

@media (min-width: 768px) {
  .c-header__logo {
    left: calc(100% - 10vw); } }

@media (min-width: 1024px) {
  .c-header__logo {
    left: 0; }
  .c-header__nav {
    top: 2rem;
    opacity: 1;
    height: 40px; }
  .c-header__region-nav {
    top: 3rem;
    opacity: 1;
    height: auto; }
  .c-header__region {
    opacity: 1;
    height: 2rem;
    top: 1.5em;
    left: 9em;
    text-align: left; }
  .c-header {
    margin-bottom: 3.5rem; } }

@media (min-width: 1200px) {
  .c-header__nav {
    overflow: visible; } }

@media (min-width: 1440px) {
  .c-header,
  .c-header__slash {
    height: 9rem; } }

.c-headline {
  position: relative;
  color: inherit;
  text-decoration: none; }

.c-headline__image {
  display: none; }

.c-headline__title {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: bold;
  font-size: 1.25rem; }

.c-headline__date {
  line-height: 1em;
  color: #888888; }

/**
 * VARIANT: MINIMAL
 */
.c-headline--minimal {
  border-bottom: 1px solid #eeeeee; }
  .c-headline--minimal .c-headline__overlay {
    padding: 7px 0;
    margin-bottom: 7px; }

/**
 * VARIANT: REGULAR
 */
.c-headline--regular {
  border-bottom: none; }
  .c-headline--regular .c-headline__image {
    width: 100%;
    display: block; }
  .c-headline--regular .c-headline__overlay {
    position: absolute;
    bottom: 0;
    width: 100%;
    background: linear-gradient(45deg, rgba(190, 54, 144, 0.93), rgba(237, 50, 75, 0.93));
    padding: 14px 14px;
    font-weight: bold;
    overflow: hidden; }
    @media (min-width: 1024px) {
      .c-headline--regular .c-headline__overlay::after {
        content: '';
        display: block;
        position: absolute;
        width: 200px;
        height: 200px;
        top: -5em;
        right: -9em;
        background: repeating-linear-gradient(105deg, #ffffff, #ffffff 3px, transparent 3px, transparent 10px);
        transform: rotate(30deg); } }
  .c-headline--regular .c-headline__title {
    text-transform: uppercase;
    font-size: 1.5rem;
    padding-bottom: 0.25rem;
    color: #ffffff; }
  .c-headline--regular .c-headline__date {
    color: #222222; }

.c-headlines {
  display: grid;
  grid-column-gap: 14px;
  grid-template-rows: auto;
  grid-template-areas: "g1" "more";
  margin-top: 1rem; }

.c-headlines__1 {
  grid-area: g1;
  margin-bottom: 14px; }

.c-headlines__2 {
  grid-area: g2;
  display: none; }

.c-headlines__3 {
  grid-area: g3;
  display: none; }

.c-headlines__4 {
  grid-area: g4;
  display: none; }

.c-headlines__5 {
  grid-area: g5;
  display: none; }

.c-headlines__more {
  grid-area: more;
  align-self: end; }

@media (min-width: 768px) {
  .c-headlines {
    grid-template-columns: minmax(0, 2fr) minmax(0, 1fr);
    grid-template-rows: auto auto auto 1fr;
    grid-template-areas: "g1 g2" "g1 g3" "g1 g4" "g1 more"; }
  .c-headlines__1 {
    margin-bottom: 0; }
  .c-headlines__2 {
    margin-bottom: 7px;
    display: block; }
  .c-headlines__3 {
    display: block; }
  .c-headlines__4 {
    border: none;
    display: block; } }

@media (min-width: 1024px) {
  .c-headlines {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    grid-template-rows: auto auto auto auto 1fr;
    grid-template-areas: "g1 g1" "g2 g3" "g2 g4" "g2 g5" "g2 more"; }
  .c-headlines__1 {
    margin-bottom: 14px; }
  .c-headlines__2 {
    margin-bottom: 0; }
  .c-headlines__4 {
    border-bottom: 1px solid #eeeeee; }
  .c-headlines__5 {
    border: none;
    display: block; } }

.c-link {
  color: inherit; }

.c-link--subtle {
  color: inherit;
  text-decoration: none; }

.c-nav {
  font-size: 1.25rem;
  font-weight: bold;
  line-height: 1.5rem; }

.c-nav__item {
  color: white !important; }

.c-nav__dropdown-content {
  display: none;
  position: absolute;
  background-color: #3c447b;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1; }
  .c-nav__dropdown-content a {
    display: block; }

.c-nav__dropdown:hover .c-nav__dropdown-content {
  display: block; }

@media (min-width: 1024px) {
  .c-nav {
    display: flex;
    justify-content: flex-end; }
  .c-nav__item {
    margin-left: 7px;
    white-space: nowrap; }
    .c-nav__item:hover {
      border-bottom: solid 3px white; } }

.c-region-nav {
  font-size: 1.25rem;
  font-weight: bold;
  line-height: 1.5rem; }

.c-region-nav__item {
  color: #be368f !important; }

@media (min-width: 1024px) {
  .c-region-nav {
    display: flex;
    justify-content: flex-end; }
  .c-region-nav__item {
    margin-left: 7px; }
    .c-region-nav__item:hover {
      border-bottom: solid 3px #be368f; } }

.c-teamstat {
  padding: 1rem 3rem;
  display: inline-block; }
  .c-teamstat .stat-value {
    font-size: 200%;
    font-weight: 600; }

.c-dropdown {
  position: relative;
  cursor: pointer;
  display: block; }
  .c-dropdown.c-button-group__button {
    display: inline-block; }
  .c-dropdown .menu {
    background: white;
    border: 2px solid #222222 !important;
    border-radius: 0 !important;
    position: absolute;
    top: 100%;
    left: 0;
    display: none;
    float: left;
    min-width: 160px;
    padding: 5px 0;
    margin: 2px 0 0;
    list-style: none;
    text-align: left;
    background-clip: padding-box;
    z-index: 9999; }
    .c-dropdown .menu a {
      padding: 5px 10px;
      display: inline-block; }
  .c-dropdown--open .menu {
    display: block; }
  .c-dropdown .icon-inner {
    margin-left: 10px;
    position: relative;
    top: 6px;
    font-size: 24px;
    margin-top: -10px; }

.u-grid__divisions .c-dropdown .menu {
  right: 0.7em !important;
  left: auto !important; }

@media screen and (max-width: 30em) {
  .u-grid__divisions .c-dropdown .menu {
    width: 100% !important;
    right: 0 !important; } }

.pin-map-team-list-item {
  display: grid;
  grid-template-columns: 25% 75%;
  background-color: #f1f1f1;
  margin-top: 2em;
  border-radius: 5px; }
  .pin-map-team-list-item-logo {
    align-items: center;
    justify-content: center;
    text-align: center; }
  .pin-map-team-list-item-name {
    margin: 1.5em;
    font-weight: bold; }

/**
 * UTILITIES (u-)
 */
.u-left {
  text-align: left; }

.u-center {
  text-align: center; }

.u-right {
  text-align: right; }

.u-vertical-center {
  display: flex;
  justify-content: center;
  align-items: center; }

.u-aux {
  color: #888888; }

.u-grid {
  display: grid;
  grid-column-gap: 28px;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: auto;
  grid-template-areas: "header" "main" "footer";
  margin: 0 auto;
  max-width: 1200px;
  padding: 0 14px; }

.u-grid__header {
  grid-area: header; }

.u-grid__main {
  grid-area: main; }

.u-grid__footer {
  grid-area: footer; }

.grid__address {
  grid-area: main; }

/**
 * LAYOUT: BASIC
 */
/**
 * LAYOUT: HOME
 */
.u-grid--home {
  grid-template-areas: "header" "main" "fixtures" "results" "footer"; }
  .u-grid--home .u-grid__fixtures {
    grid-area: fixtures; }
  .u-grid--home .u-grid__results {
    grid-area: results; }
  @media (min-width: 768px) {
    .u-grid--home {
      grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
      grid-template-areas: "header header" "main main" "fixtures results" "footer footer"; } }
  @media (min-width: 1024px) {
    .u-grid--home {
      grid-template-columns: minmax(0, 1fr) minmax(0, 2fr);
      grid-template-areas: "header header" "fixtures main" "results main" "footer footer"; } }

.u-grid__contact {
  display: grid;
  grid-template-columns: 100%; }
  @media (min-width: 1024px) {
    .u-grid__contact {
      grid-template-columns: 50% 50%; } }
  @media (min-width: 768px) {
    .u-grid__contact {
      grid-template-columns: 50% 50%; } }
  @media (min-width: 1440px) {
    .u-grid__contact {
      grid-template-columns: 30% 70%; } }

/**
 * LAYOUT: TEAM
 */
.u-grid--team {
  grid-template-areas: "header" "main" "fixtures" "results" "footer"; }
  .u-grid--team .u-grid__fixtures {
    grid-area: fixtures; }
  .u-grid--team .u-grid__results {
    grid-area: results; }
  .u-grid--team .u-grid__team_details {
    display: grid;
    grid-template-columns: 1fr; }
  .u-grid--team .u-grid__stats {
    display: grid;
    grid-template-columns: auto auto; }
  .u-grid--team .u-grid__divisions {
    grid-column: 2;
    text-align: right; }
    .u-grid--team .u-grid__divisions .c-button {
      margin-right: 10px; }
  .u-grid--team .u-grid__logo {
    justify-self: center; }
  @media (min-width: 768px) {
    .u-grid--team {
      grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
      grid-template-areas: "header header" "main main" "fixtures results" "footer footer"; }
      .u-grid--team .u-grid__team_details {
        grid-template-columns: 1fr 3fr; } }
  @media (min-width: 1024px) {
    .u-grid--team {
      grid-template-columns: minmax(0, 1fr) minmax(0, 2fr);
      grid-template-areas: "header header" "main main" "results fixures" "footer footer"; }
      .u-grid--team .u-grid__team_details {
        grid-template-columns: 1fr 3fr; }
      .u-grid--team .u-grid__team_details {
        grid-template-columns: 1fr 3fr; }
      .u-grid--team .u-grid__stats {
        grid-template-columns: auto auto auto; } }

.u-send-to-back {
  position: relative;
  z-index: -1; }

.u-small {
  font-size: 11.2px;
  line-height: 16.8px;
  text-transform: uppercase; }

.u-nowrap {
  white-space: nowrap; }

.u-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.u-text-body {
  font-size: 16.8px;
  line-height: 25.2px; }

/**
 * INVISIBLE
 * Hide an element, but still allow it to take up space in the document.
 */
.u-invisible {
  visibility: hidden; }

/**
 * HIDE
 * Effectively remove the element from the document.
 */
.u-hide {
  display: none; }

/**
 * RESPONSIVE
 * Generate `u-hide-?` and `u-show-?` classes for each breakpoint, that can be
 * used to hide content up to or above the breakpoint. For example:
 *
 * `u-hide-tablet-landscape` will hide the element at 1024px or above.
 * `u-show-tablet-landscape` will hide the element at 1023px or below.
 */
@media (min-width: 320px) {
  .u-hide-mobile-portrait {
    display: none; } }

@media (max-width: 319px) {
  .u-show-mobile-portrait {
    display: none; } }

@media (min-width: 480px) {
  .u-hide-mobile-landscape {
    display: none; } }

@media (max-width: 479px) {
  .u-show-mobile-landscape {
    display: none; } }

@media (min-width: 768px) {
  .u-hide-tablet-portrait {
    display: none; } }

@media (max-width: 767px) {
  .u-show-tablet-portrait {
    display: none; } }

@media (min-width: 1024px) {
  .u-hide-tablet-landscape {
    display: none; } }

@media (max-width: 1023px) {
  .u-show-tablet-landscape {
    display: none; } }

@media (min-width: 1200px) {
  .u-hide-desktop {
    display: none; } }

@media (max-width: 1199px) {
  .u-show-desktop {
    display: none; } }

.c-news-article {
  margin-bottom: 4rem; }

.c-document {
  margin: 2rem 0;
  padding-bottom: 2rem;
  border-bottom: 2px solid #eeeeee; }

.c-gallery-preview,
.c-news-article {
  position: relative; }

.c-gallery-preview__more,
.c-news-article__more {
  margin-top: 1rem; }
  @media (min-width: 768px) {
    .c-gallery-preview__more,
    .c-news-article__more {
      position: absolute;
      top: calc(1rem - 2px);
      right: calc(1rem - 2px);
      margin: 0; } }

.embed-container {
  position: relative;
  padding-bottom: 4500px;
  height: 0;
  overflow: hidden;
  max-width: 100%; }

.embed-container iframe,
.embed-container object,
.embed-container embed {
  border: none;
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
  width: 0; }

.list-sentence {
  display: inline;
  list-style: none;
  margin: 0;
  padding: 0; }

.list-sentence li {
  display: inline; }

.list-sentence li:after {
  content: ', '; }

.list-sentence li:last-child:after {
  content: ''; }

.scores {
  padding-right: 0 !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center; }

.scores-team {
  width: 40%; }

.scores-score {
  width: 4rem;
  font-weight: bold; }

.scores-vs {
  width: 2.5rem;
  text-align: center; }

.scores-right {
  text-align: right; }

.scores + .meta-info {
  border-top: 1px solid #f5f5f5;
  text-align: center;
  max-width: 60em;
  padding-top: 0.5em;
  margin-left: auto;
  margin-right: auto; }

@media screen and (max-width: 40em) {
  .scores-team:last-child {
    text-align: right; }
  .scores-team:first-child {
    text-align: left; } }

.event {
  margin-bottom: 2rem;
  padding-left: 50%;
  position: relative; }

.event--home {
  padding-left: 0;
  padding-right: 50%; }

.event__icon {
  background: white;
  color: #aaa;
  left: 50%;
  margin-left: -2rem;
  position: absolute;
  text-align: center;
  top: 0;
  width: 4rem;
  padding: 0.5rem 0; }

.event__panel {
  margin-left: 4rem;
  padding: 0.5rem 0;
  position: relative;
  text-align: left; }

.event__aux {
  color: #aaa; }

.event__notes {
  color: #aaa;
  font-size: 90%;
  font-style: italic; }

.event__notes i {
  margin-right: 0.5rem; }

.event--flow {
  background: #fff;
  color: #aaa;
  margin-bottom: 2rem;
  margin-left: 0;
  padding: 0.5rem;
  text-align: center; }

.event--home .event__panel {
  margin-left: 0;
  margin-right: 4rem;
  text-align: right; }

.event--home .event__panel::before {
  border-color: transparent;
  border-left-color: #eee;
  left: auto;
  right: calc(-1rem + 1px); }

.event__action {
  display: none; }

.styles_overlay__CLSq- {
  z-index: 99; }

@media screen and (max-width: 32em) {
  .event__panel {
    margin-left: 1rem; }
  .event--home .event__panel {
    margin-right: 1rem; }
  .event__icon {
    margin-left: -5rem;
    text-align: right; }
  .event--home .event__icon {
    margin-left: 1rem;
    text-align: left; } }

.timeline {
  list-style: none;
  padding: 1em 0;
  position: relative; }

.timeline__item:last-child .event {
  margin-bottom: 0; }

.timeline__item--clock {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center; }

.timeline__item--clock .event {
  font-weight: bold;
  color: inherit;
  padding: 0em 1em 0.75em;
  font-size: 1.5em; }

.timeline::before {
  background: #eee;
  bottom: 2em;
  content: '';
  display: block;
  left: 50%;
  margin-left: -1px;
  position: absolute;
  top: 2em;
  width: 2px; }
